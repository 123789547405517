import '../scss/main.scss';
import '../scss/error.scss';
import './_common.js';

let backgrounds = [
  {
    class: 'white',
    dark: false
  },
  {
    class: 'purple',
    dark: false
  },
  {
    class: 'black',
    dark: true
  },
  {
    class: 'black-light',
    dark: true
  },
  {
    class: 'black-snowflake',
    dark: true
  }
];

let background = backgrounds[1];
let $content = document.getElementById('content');
if ($content && $content.classList.contains('error-404')) {
  background = backgrounds[Math.floor(Math.random() * backgrounds.length)];
}

document.body.classList.add(background.class);
document.body.classList.add(background.dark ? 'dark' : 'light');
